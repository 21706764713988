import React from 'react';
import styled from 'styled-components';

import { Header } from 'components/header/Header';
import { HeaderLink } from 'components/header/HeaderLink';

import { responsiveFont } from 'styles/utils';
import { variables } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';

import { Work } from 'components/work/Work';
import { WorkBlock } from 'components/work/WorkBlock';
import { WorkBlockImage } from 'components/work/WorkBlockImage';
import { WorkBlockTextLeft } from 'components/work/WorkBlockTextLeft';

import { graphql } from 'gatsby';

import Helmet from 'react-helmet';

import ReelClip from '../../assets/video/showreel2022_clip.mp4';
import ReelVideo from '../../assets/video/showreel2022.mp4';
import ReelVideoPath from '../../assets/images/showreel2022.jpeg';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SimpleFooter } from 'components/footer/SimpleFooter';
import VideoPlayer from 'components/video/VideoPlayer';
import ShowreelFilter from 'components/work/ShowreelFilter';

// tslint:disable no-default-export

const Wrapper = styled.div`
  padding: 0px;
`;

const StrongText = styled.p`
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 15px;
  display: none;
`;

const AwardTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 500;
`;

const AwardText = styled.p`
  margin-bottom: 5px;
  font-weight: 400;
  opacity: 0.5;
`;
const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  padding-bottom: 20px;
`;

export default ({ data, location }) => {
  const { t } = useTranslation();
  const playerRef = React.useRef<HTMLVideoElement>(null);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [controls, setControls] = React.useState(true);

  const options = {
    preload: 'auto',
    controls: true,
    fullscreen: {
      options: {
        navigationUI: 'hide',
      },
    },
    userActions: {
      doubleClick: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('useractive', () => {
      setControls(true);
    });

    player.on('userinactive', () => {
      setControls(false);
    });

    player.on('ended', () => {
      setFullscreen(false);
      playerRef?.current?.currentTime(0);
    });

    player.on('fullscreenchange', () => {
      if (!player.isFullscreen()) {
        handleStop();
      }
    });
  };

  const handlePlay = (source) => {
    // @ts-ignore
    playerRef?.current?.src({ src: source, type: 'video/mp4' });
    setFullscreen(true);
    playerRef?.current?.play();
  };

  const handleStop = () => {
    setFullscreen(false);
    playerRef?.current?.pause();
  };

  return (
    <>
      <Header>
        <HeaderLink name={t('work')} href="/work" className="text-black" />
        <HeaderLink name={t('meet')} href="/contact" className="text-black" />
      </Header>
      <Helmet title="Showreels" />

      <Container>
        <Wrapper>
          <Row>
            <Column md={12} lg={12} sm={12}>
              <SectionTitle>{t('showreelTitle')}</SectionTitle>
              <ShowreelFilter location={location} />
            </Column>
          </Row>
        </Wrapper>
      </Container>

      <Work>
        <WorkBlock>
          <div className="mt-10 md:mt-0 lg:mt-0 w-full pb-32">
            <WorkBlockImage external="video" lg={12} md={12}>
              <video
                preload="auto"
                muted
                playsInline
                loop
                autoPlay
                poster={ReelVideoPath}
                className="w-full cursor-pointer"
                src={ReelClip}
                onClick={() => handlePlay(ReelVideo)}
              />
            </WorkBlockImage>
          </div>
        </WorkBlock>
      </Work>
      <Container>
        <Wrapper>
          <Row>
            <Column sm={12}>
              <SimpleFooter />
            </Column>
          </Row>
        </Wrapper>
      </Container>
      {/* fullscreen Video */}
      <div
        className={
          !fullscreen
            ? 'opacity-0 invisible transition-opacity w-0 h-0'
            : 'opacity-100 visible z-10 w-full h-screen top-0 left-0 transition-opacity fixed'
        }
      >
        <VideoPlayer options={options} onReady={handlePlayerReady} />
        <button
          className={`${
            !controls ? 'opacity-0 delay-500' : 'opacity-100'
          } transition-opacity duration-700  absolute right-2 top-2 w-10 h-10 border text-white`}
          onClick={handleStop}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.5893 8.45868L15.932 14.1147L10.276 8.45868L8.39062 10.344L14.0466 16L8.39062 21.656L10.276 23.5413L15.932 17.8853L21.5893 23.5413L23.4746 21.656L17.8186 16L23.4746 10.344L21.5893 8.45868Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {/* / fullscreen Video */}
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contact", "index", "terms", "work"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
